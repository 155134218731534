(function (window, jQuery) {
  jQuery(function ($) {
    var $window = $(window)
    var $mainHeader = $('#main-header')
    var $mainHeaderContainer = $mainHeader.find('.header-container')
    var $navButton = $mainHeader.find('#nav-toggle-button')
    var $navItems = $mainHeader.find('.nav-items')
    var $socialIcons = $mainHeader.find('.social-icons')

    $mainHeader.css('transform', 'translateY(0%)')

    var navStatus = false

    $navButton
      .on('click', function () {
        $window.trigger('TA_nav.toggle')
      })

    $window
      .on('TA_nav.open', function () {
        navStatus = true
        $window.trigger('TA_updateUI.nav')
      })
      .on('TA_nav.close', function () {
        navStatus = false
        $window.trigger('TA_updateUI.nav')
      })
      .on('TA_nav.toggle', function () {
        navStatus = !navStatus
        $window.trigger('TA_updateUI.nav')
      })
      .on('TA_updateUI.nav', function () {
        if (navStatus) {
          $navButton.parent().addClass('open')
          $window.trigger('TA_footer.close')
        } else {
          $navButton.parent().removeClass('open')
        }
      })
      .on('TA_isMobile', function (e, isMobile) {
        if (isMobile) {
          $navItems.css('top', '')
          $socialIcons.appendTo($navItems)
        } else {
          $navItems.css('top', $mainHeader.outerHeight())
          $socialIcons.appendTo($mainHeaderContainer)
        }
      })
  })
})(window, window.jQuery)
