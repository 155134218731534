(function (window, jQuery) {
  jQuery(function ($) {
    var $window = $(window)
    var $moreContent = $('.more-text')

    if ($moreContent.length > 0) {
      $moreContent.each(function () {
        var $this = $(this)

        var $more = $this.find('.more')
        var moreText = $more.html()
        $more.remove()

        var textTruncated = $this.html()
        var textOriginal = $this.html() + moreText

        // set default label
        var moreLabel = $this.data('more-label')
        if (typeof moreLabel === 'undefined' || moreLabel.trim() === '') {
          this.data('more-label', 'more…')
        }

        $this.data('text-original', textOriginal)
        $this.data('text-truncated', textTruncated)
      })

      $window
        .on('TA_isMobile', function (e, isMobile) {
          $moreContent.each(function () {
            var $this = $(this)
            $this.html($this.data(isMobile ? 'text-truncated' : 'text-original'))

            if (isMobile) {
              // add more button
              var moreButton = $('<a>')
                .text($this.data('more-label'))
                .on('click', function (e) {
                  e.preventDefault()

                  var moreText = $(this).closest('.more-text')
                  moreText.html(moreText.data('text-original'))

                  // scroll into view
                  if (typeof moreText[0].scrollIntoView === 'function') {
                    moreText[0].scrollIntoView()
                  }
                })
              $this.append(moreButton)
            }
          })
        })
    }
  })
})(window, window.jQuery)