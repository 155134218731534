(function (window, jQuery) {
  jQuery(function ($) {
    var $window = $(window)
    var $mainHeader = $('#main-header')

    var $mainFooter = $('#main-footer')
    var footerExists = $mainFooter.length >= 1

    // footer
    if (footerExists) {
      var footerStatus = false
      $mainFooter
        .find('.handle')
        .on('click', function () {
          footerStatus = !footerStatus
          $window.trigger('TA_updateUI.footer')
        })

      $window
        .on('TA_footer.open', function () {
          footerStatus = true
          $window.trigger('TA_updateUI.footer')
        })
        .on('TA_footer.close', function () {
          footerStatus = false
          $window.trigger('TA_updateUI.footer')
        })
        .on('TA_updateUI.footer', function () {
          var contentHeight = $window.height() - $mainHeader.outerHeight()
          var footerHandleHeight = $mainFooter.find('.handle').outerHeight()

          $mainFooter.outerHeight(contentHeight)

          var $scrollableContent = $mainFooter.find('.scrollable-content')
          $scrollableContent.outerHeight(contentHeight - footerHandleHeight + parseInt($scrollableContent.css('margin-top')) * -1)

          if (footerStatus) {
            $mainFooter
              .css('transform', 'translateY(0px)')
              .addClass('open')
            $window.trigger('TA_nav.close')
          } else {
            $mainFooter
              .css('transform', 'translateY(' + (contentHeight - footerHandleHeight) + 'px)')
              .removeClass('open')
          }
        })
    }

    $window.trigger('TA_updateUI.footer')

    $('.trigger-footer').on('click', function (e) {
      e.preventDefault()
      $window.trigger('TA_footer.open')
    })
  })
})(window, window.jQuery)