(function (window, jQuery) {
  jQuery(function ($) {
    var $window = $(window)
    var $mainContent = $('#main-content')
    var $tapirBackground = $mainContent.find('>.tapir-background')

    if ($tapirBackground.length > 0) {
      if ($tapirBackground.hasClass('tapir-in-the-jungle')) {
        $tapirBackground
          .append(
            $('<div class="layer water">'),
            $('<div class="layer jungle">'),
            $('<div class="layer leaf-0">'),
            $('<img class="layer leaf-1" src="../images/bg_leaf-1.png">'),
            $('<img class="layer leaf-2" src="../images/bg_leaf-2.png">'),
            $('<img class="layer leaf-3" src="../images/bg_leaf-3.png">'),
            $('<div class="layer tapir">')
          )
      } else if ($tapirBackground.hasClass('tapir-in-the-wild')) {
        $tapirBackground
          .append(
            $('<div class="layer tapir">')
          )
      }

      $tapirBackground.imagesLoaded(function () {
        $tapirBackground.css('opacity', 1)
      })
    }
  })
})(window, window.jQuery)